'use client'

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'

import { usePathname } from 'next/navigation'

export interface ILayoutContext {
  mobileMenuOpen: boolean
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
}

export const LayoutContext = createContext<ILayoutContext>({
  mobileMenuOpen: false,
  setMobileMenuOpen: () => null
})

export function useLayoutContext() {
  return useContext(LayoutContext)
}

export default function LayoutContextProvider({
  children
}: {
  children: ReactNode
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const pathname = usePathname()

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [pathname])

  return (
    <LayoutContext.Provider value={{ mobileMenuOpen, setMobileMenuOpen }}>
      {children}
    </LayoutContext.Provider>
  )
}
