import { SVGProps } from 'react'

const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={
      {
        enableBackground: 'new 0 0 96 53'
      } as any
    }
    viewBox="0 0 96 53"
    fill="currentColor"
    {...props}
  >
    <path d="m80.2 29.8 11.9-14H81.9L70.5 29.3v-24H55.7L46.3 30l-11-25.5h-2.9L21.3 30.2 12 5.3H2.4l16.8 42.9H22l11.9-26.5 11.7 26.5h2.8l13.5-34.5v33.7h8.6V29.9l12.7 17.5h9.9z" />
  </svg>
)
export default Logo
