'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

export default function Navbar({
  navigation
}: {
  navigation: { LABEL: string; SLUG: string }[]
}) {
  const pathname = usePathname()

  return (
    <div className="hidden lg:flex lg:gap-x-12">
      {navigation.map(
        ({ LABEL, SLUG }: { LABEL: string; SLUG: string }, index: number) => (
          <Link
            key={index}
            href={`${SLUG}`}
            className={`text-sm font-normal leading-6 transition-colors  duration-200 hover:text-brand-pink ${
              pathname === `${SLUG}` ? 'text-brand-pink' : 'text-white'
            }`}
          >
            {LABEL}
          </Link>
        )
      )}
    </div>
  )
}
