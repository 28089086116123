'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

export default function Navigation({
  navigation
}: {
  navigation: { LABEL: string; SLUG: string }[]
}) {
  const pathname = usePathname()

  return (
    <div className="mt-6 flow-root">
      <div className="-my-6 divide-y divide-gray-500/25">
        <div className="space-y-2 py-6">
          {navigation.map(
            (
              { LABEL, SLUG }: { LABEL: string; SLUG: string },
              index: number
            ) => (
              <Link
                key={index}
                href={`${SLUG}`}
                className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7  transition-colors duration-200  hover:bg-blue-950 hover:text-brand-pink ${
                  pathname === `${SLUG}` ? 'text-brand-pink' : 'text-white'
                }`}
              >
                {LABEL}
              </Link>
            )
          )}
        </div>
        <div className="py-6">
          {/*  <a
          href="#"
          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
        >
          Log in
        </a> */}
        </div>
      </div>
    </div>
  )
}
