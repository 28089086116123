'use client'
import { ReactNode } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import Link from 'next/link'

import Logo from '@/app/[locale]/(main-layout)/components/ui/Icons/Logo'
import { useLayoutContext } from '@/contexts/LayoutContext'

export default function CollapsibleNav({
  children
}: {
  navigation: { LABEL: string; SLUG: string }[]
  children: ReactNode
}) {
  const { mobileMenuOpen, setMobileMenuOpen } = useLayoutContext()
  return (
    <>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white transition-colors duration-200  hover:bg-blue-950 hover:text-brand-pink"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-40" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-brand-blue px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <Link href={`/`} className="-m-1.5 p-1.5">
              <span className="sr-only">Worpik</span>
              <Logo className="h-8 w-auto text-white transition-colors  duration-200 hover:text-brand-pink" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white transition-colors  duration-200 hover:bg-blue-950 hover:text-brand-pink"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {children}
        </Dialog.Panel>
      </Dialog>
    </>
  )
}
